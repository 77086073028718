import React from "react";
import Layout from "../../components/layout";
import {
  Container,
  H1,
  NextStep,
  PrevStep,
  StepIndicator,
} from "../../styles/ui";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import Contact from "../../components/contact";
import Card from "../../components/card";
import { FiCheckCircle } from "react-icons/fi";

const NovekedesiStrategiaKidolgozasa: React.FC = () => {
  return (
    <Layout
      allowTransparentHeader={true}
      variant="marketing"
      title="Növekedési stratégia kidolgozása"
      description="Növekedési stratégia kidolgozása"
    >
      <Wrapper>
        <Inner>
          <div
            css={css`
              grid-area: txt;
              h3 {
                margin: 32px 0;
              }
            `}
          >
            <StepIndicator step={2} />
            <H1>
              Növekedési <span>stratégia</span> kidolgozása
            </H1>
            <p>
              A marketing-audit során feltárt stratégia könnyítő és -nehezítő
              körülmények figyelembevételével kijelöljük a szervezet számára a
              potenciális növekedési lehetőségeket (termék-piac viszonylatban).
              Mindez, természetesen az Ön cégének egyedi helyzetét figyelembe
              véve, magába foglalja például a piacválasztást, a megkülönböztető
              előnyt, valamint az egyes marketing-mix és kereskedelmi
              stratégiákat is.
            </p>
            <h3>TERMÉKSTRATÉGIA KONCEPCIÓ</h3>
            <p>
              A piacvezérelt marketing termékfelfogása azt hangsúlyozza, hogy a
              terméket a vevő, a fogyasztó vagy a felhasználó szemszögéből kell
              vizsgálni. Bármennyire is természetesnek hangzik ez a követelmény,
              a gyakorlat azt bizonyítja, hogy a termelők számára a termék -
              tulajdonképpen érthető módon - anyagok, alkatrészek,
              munkafolyamatok, technológiák, műszaki paraméterek,
              csomagolóanyagok stb. összességét jelenti. Fontos, hogy megértsük,
              a vevő mely szükségleteire jelent megoldást a termékünk
              (szolgáltatásunk), hisz a stratégiának is ezekre a tényezőkre kell
              épülniük.
            </p>
            <h3>ÁRSTRATÉGIA KONCEPCIÓ</h3>
            <p>
              A meglévő és potenciális ügyfelek döntését nagymértékben
              befolyásolja a termékek, szolgáltatások árazása, sőt, akadnak
              olyan társadalmak, mint például a miénk is, amelyben talán az
              egyik legfontosabb tényező, amely alapján megszületik a végleges
              vásárlási döntés. Ennek tükrében fontos egyrészt megvizsgálni
              fogyasztói oldalról például azt, hogy milyen árelfogadással
              rendelkeznek ügyfeleink, vagy milyen minőségre asszociálnak a
              termékeink, szolgáltatásaink végső árából, másrészt pedig
              vállalati oldalról tisztában kell lennünk a termék, szolgáltatás
              előállításához és értékesítéséhez kötődő költségek ismeretével is.
              Mindez meghatározza és befolyásolja a sikeres árstratégia
              kialakítását.
            </p>
          </div>
          <div
            css={css`
              grid-area: card;
            `}
          >
            <Card>
              <h2>Módszereink</h2>
              <ul style={{ padding: 0 }}>
                <Li>BCG mátrix</Li>
                <Li>Ansoff mátrix</Li>
                <Li>Portfolio elemzés</Li>
              </ul>
            </Card>
          </div>
          <PrevNext
            css={css`
              grid-area: nav;
            `}
          >
            <PrevStep step={1} name={"Személyre szabott diagnosztika"} />
            {/* <div /> */}
            <NextStep step={3} name={"Megvalósítás"} />
          </PrevNext>
        </Inner>
      </Wrapper>
      <Contact variant="marketing" />
    </Layout>
  );
};

export default NovekedesiStrategiaKidolgozasa;

interface LiProps {
  children?: React.ReactNode;
}
const Li: React.FC<LiProps> = ({ children }) => (
  <li
    css={css`
      list-style: none;
      display: grid;
      grid-template-columns: 20px 1fr;
      column-gap: 8px;
      margin-bottom: 16px;
      svg {
        display: inline-block;
        width: 20px;
        height: 20px;
        color: var(--color-primary);
        fill: none;
      }
      p {
        display: inline-block;
      }
    `}
  >
    <FiCheckCircle />
    <p>{children}</p>
  </li>
);

const Wrapper = styled(Container)`
  margin: 160px 0 96px 0;
`;

const Inner = styled.div`
  display: grid;
  grid-template-columns: 1fr 400px;

  grid-template-areas:
    "txt card"
    "nav card";
  column-gap: 32px;
  row-gap: 64px;

  h2 {
    font-weight: 600;
    margin-bottom: 16px;
  }

  @media (max-width: 1310px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "txt"
      "card"
      "nav";
  }
`;

const PrevNext = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 32px;
  row-gap: 32px;
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;
